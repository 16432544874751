import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

const Section = styled.section`
  width: 100%;
`;

const SectionWrapper = styled.div`
  width: 94%;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 1920px) {
    max-width: 1330px;
  }
`;

const SectionCenterTitle = styled.h2`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: #7e913b;

  @media screen and (max-width: 670px) {
    font-size: 3.25rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 1024px) {
    font-size: 2.875rem;
  }

  @media screen and (min-width: 671px) {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  @media screen and (min-width: 1025px) {
    font-size: 112px;
    font-size: 7rem;
  }

  @media screen and (min-width: 671px) {
    &:after {
      content: "";
      min-width: 20px;
      height: 2px;
      -webkit-flex-grow: 1;
      flex-grow: 1;
      background: #7e913b;
      margin-left: 60px;
    }
  }

  @media screen and (min-width: 671px) {
    &:before {
      content: "";
      min-width: 20px;
      height: 2px;
      -webkit-flex-grow: 1;
      flex-grow: 1;
      background: #7e913b;
      margin-right: 60px;
    }
  }
`;

const PostClusterWrapper = styled.div`
  @media screen and (min-width: 769px) {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: center;
    align-items: center;
  }
`;
const PostClusterMain = styled.div`
  @media screen and (min-width: 769px) {
    width: 48.125%;
    margin-left: 3.75%;
  }
`;

const PostClusterMainArticle = styled.article``;

const PostClusterMainArticlePreviewImageWrapper = styled(Link)`
  position: relative;
  display: block;
  overflow: hidden;
  text-decoration: none;
  color: #303c3c;
`;

const PostClusterMainArticlePreviewImage = styled(GatsbyImage)`
  transition: -webkit-transform ease 1.6s;
  transition: transform ease 1.6s;
  transition: transform ease 1.6s, -webkit-transform ease 1.6s;
  width: 100%;
  display: block;
  max-width: 100%;
  height: auto;
`;

const PostClusterMainArticlePreviewPicture = styled.div`
  max-width: 100%;
  height: auto;
`;

const PostClusterMainArticlePreviewTextWrapper = styled.div`
position: relative;
background: #fff;
z-index: 1;
text-align: center;

@media screen and (max-width: 670px){
    margin: -10px 10px 0px 10px;
    padding: 20px;
}



@media screen and (min-width: 671px){
    margin: -20px 20px 0 20px;
}
padding: 20px 40px 30px;
@media screen and (min-width: 1025px){
    padding: 20px 50px 40px;
}
`;

const PostClusterMainArticlePreviewTextCategory = styled.span`
  display: inline-block;
  border-bottom: 2px solid transparent;
  font-family: "Epilogue", "Arial", sans-serif;
  font-weight: 700;
  color: #7e913b;
  text-transform: uppercase;
  -webkit-letter-spacing: 0.1em;
  -moz-letter-spacing: 0.1em;
  -ms-letter-spacing: 0.1em;
  letter-spacing: 0.1em;
`;

const PostClusterMainArticlePreviewTitle = styled.p`
  margin-top: 25px;
  line-height: 180%;

  @media screen and (min-width: 1025px) {
    font-size: 1.625rem;
  }
`;

const PostClusterMainArticlePreviewTitleLink = styled(Link)`
  text-decoration: none;
  color: #303c3c;
`;

/* Smaller Columns */

const PostClusterColumn = styled.div`
@media screen and (max-width: 768px){
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-top: 3.75%;
}

@media screen and (min-width: 769px){
  width: 22.1875%;
}

&:nth-child(2){
  -webkit-order: -1;
  order: -1;
}

&:nth-child(3){
  margin-left: 3.75%;
}
`;

const PostClusterColumnArticle = styled.article`
  @media screen and (max-width: 768px) {
    width: 48.125%;
  }

  @media screen and (min-width: 769px) {
    & :nth-child(n + 2) {
      margin-top: 40px;
    }
  }

  & :nth-child(3) {
    margin-left: 3.75%;
  }

  @media screen and (max-width: 768px) {
    & :nth-child(2) {
      margin-left: 3.75%;
    }
  }
`;

const PostClusterColumnArticlePreviewImageWrapper = styled(Link)`
  position: relative;
  display: block;
  overflow: hidden;
  text-decoration: none;
  color: #303c3c;
`;

const PostClusterColumnArticlePreviewPicture = styled.div`
  max-width: 100%;
  height: auto;
`;

const PostClusterColumnArticlePreviewImage = styled(GatsbyImage)`
  transition: -webkit-transform ease 1.6s;
  transition: transform ease 1.6s;
  transition: transform ease 1.6s, -webkit-transform ease 1.6s;
  width: 100%;
  display: block;
  display: block;
  max-width: 100%;
  height: auto;
`;
const PostClusterColumnArticlePreviewTextWrapper = styled.div`
  @media screen and (max-width: 670px) {
    padding-top: 10px;
  }

  @media screen and (min-width: 671px) {
    padding: 20px 10px 0;
  }

  text-align: center;
`;

const PostClusterColumnArticlePreviewTextCategory = styled.span`
  display: inline-block;
  border-bottom: 2px solid transparent;
  font-family: "Epilogue", "Arial", sans-serif;
  font-weight: 700;
  color: #7e913b;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  @media screen and (max-width: 670px) {
    font-size: 0.75rem;
  }
`;

const PostClusterColumnArticlePreviewTitle = styled.p`
  line-height: 180%;
  @media screen and (max-width: 670px) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 670px) {
    font-size: 1rem;
    line-height: 180%;
  }

  @media screen and (min-width: 671px) {
    margin-top: 20px;
  }

  @media screen and (min-width: 1025px) {
    font-size: 1.125rem;
  }
`;

const PostClusterColumnArticlePreviewTitleLink = styled(Link)`
  text-decoration: none;
  color: #303c3c;
`;

const GoodReads = ({ data }) => {
  if (!data) return null;
  const posts = data.edges;
  return (
    <Section>
      <SectionWrapper>
        <SectionCenterTitle>
          <span>特好看</span>
        </SectionCenterTitle>
        <PostClusterWrapper>
          {posts &&
            posts.slice(0, 1).map(({ node: post }) => (
              <PostClusterMain key={post.id}>
                <PostClusterMainArticle>
                  <PostClusterMainArticlePreviewImageWrapper
                    to={`${post.fields.slug.replace(/\s+/g, '-')}`}
                  >
                    <PostClusterMainArticlePreviewPicture
                      as={motion.div}
                      whileHover={{
                        scale: [1, 1.1],
                        zIndex: 99,
                        transition: {
                          duration: 0.4,
                        },
                      }}
                    >
                      <PostClusterMainArticlePreviewImage
                        image={
                          post.frontmatter.featuredimage.childImageSharp.large
                        }
                        alt={`featured image thumbnail for post ${post.frontmatter.title}`}
                      />
                    </PostClusterMainArticlePreviewPicture>
                  </PostClusterMainArticlePreviewImageWrapper>
                  <PostClusterMainArticlePreviewTextWrapper>
                    <PostClusterMainArticlePreviewTextCategory>
                      {post.frontmatter.tags[0]}
                    </PostClusterMainArticlePreviewTextCategory>
                    <PostClusterMainArticlePreviewTitle>
                      <PostClusterMainArticlePreviewTitleLink
                        to={`${post.fields.slug.replace(/\s+/g, '-')}`}
                      >
                        {post.frontmatter.title}
                      </PostClusterMainArticlePreviewTitleLink>
                    </PostClusterMainArticlePreviewTitle>
                  </PostClusterMainArticlePreviewTextWrapper>
                </PostClusterMainArticle>
              </PostClusterMain>
            ))}

          <PostClusterColumn>
            {posts &&
              posts.slice(1, 3).map(({ node: post }) => (
                <PostClusterColumnArticle key={post.id}>
                  <PostClusterColumnArticlePreviewImageWrapper
                    to={`${post.fields.slug.replace(/\s+/g, '-')}`}
                  >
                    <PostClusterColumnArticlePreviewPicture
                      as={motion.div}
                      whileHover={{
                        scale: [1, 1.1],
                        zIndex: 99,
                        transition: {
                          duration: 0.4,
                        },
                      }}
                    >
                      <PostClusterColumnArticlePreviewImage
                        image={
                          post.frontmatter.featuredimage.childImageSharp.small
                        }
                        alt={`featured image thumbnail for post ${post.frontmatter.title}`}
                      />
                    </PostClusterColumnArticlePreviewPicture>
                  </PostClusterColumnArticlePreviewImageWrapper>

                  <PostClusterColumnArticlePreviewTextWrapper>
                    <PostClusterColumnArticlePreviewTextCategory>
                      {post.frontmatter.tags[0]}
                    </PostClusterColumnArticlePreviewTextCategory>
                    <PostClusterColumnArticlePreviewTitle>
                      <PostClusterColumnArticlePreviewTitleLink
                        to={`${post.fields.slug.replace(/\s+/g, '-')}`}
                      >
                        {post.frontmatter.title}
                      </PostClusterColumnArticlePreviewTitleLink>
                    </PostClusterColumnArticlePreviewTitle>
                  </PostClusterColumnArticlePreviewTextWrapper>
                </PostClusterColumnArticle>
              ))}
          </PostClusterColumn>

          <PostClusterColumn>
            {posts &&
              posts.slice(3, 5).map(({ node: post }) => (
                <PostClusterColumnArticle key={post.id}>
                  <PostClusterColumnArticlePreviewImageWrapper
                    to={`${post.fields.slug.replace(/\s+/g, '-')}`}
                  >
                    <PostClusterColumnArticlePreviewPicture
                      as={motion.div}
                      whileHover={{
                        scale: [1, 1.1],
                        zIndex: 99,
                        transition: {
                          duration: 0.4,
                        },
                      }}
                    >
                      <PostClusterColumnArticlePreviewImage
                        image={
                          post.frontmatter.featuredimage.childImageSharp.small
                        }
                        alt={`featured image thumbnail for post ${post.frontmatter.title}`}
                      />
                    </PostClusterColumnArticlePreviewPicture>
                  </PostClusterColumnArticlePreviewImageWrapper>

                  <PostClusterColumnArticlePreviewTextWrapper>
                    <PostClusterColumnArticlePreviewTextCategory>
                      {post.frontmatter.tags[0]}
                    </PostClusterColumnArticlePreviewTextCategory>
                    <PostClusterColumnArticlePreviewTitle>
                      <PostClusterColumnArticlePreviewTitleLink
                        to={`${post.fields.slug.replace(/\s+/g, '-')}`}
                      >
                        {post.frontmatter.title}
                      </PostClusterColumnArticlePreviewTitleLink>
                    </PostClusterColumnArticlePreviewTitle>
                  </PostClusterColumnArticlePreviewTextWrapper>
                </PostClusterColumnArticle>
              ))}
          </PostClusterColumn>
        </PostClusterWrapper>
      </SectionWrapper>
    </Section>
  );
};

export default GoodReads;
